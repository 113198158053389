import React, { useEffect, useState } from "react"
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink, faUnlink, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useLocation } from "react-router-dom";
import { LinkPlaid } from "../plaid/link"
import ClientPLaidItemDelete from "./clientPlaidDelete";
import { PlaidItemCreate } from "../plaid/plaidItemCreate";
import { PlaidBankTransactionExport } from "../plaid/plaidBankTransaction";
import EditQuickbooksName from "../account/editQuickbooksName";
import { Alert, Table, Thead, Tbody, Th, Tr, Td, Stack, Container, Heading, Flex, Box, Spacer, HStack, Tooltip } from "@chakra-ui/react";
import { Context } from "../../ContextWrapper";
import { AlertCircle } from "react-feather";
import CustomTableContainer from "../../theme/components/tableContainer";
import { DropDown } from "../generic/dropDown";


export const ClientPlaid = ({customer_id, isAdmin}) => {
    const [plaidItemList, setPlaidItemList] = useState([]);
    const [accountList, setAccountList] = useState([]);
    const location = useLocation();
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(true);
    const context = React.useContext(Context);

    useEffect(() => {
      if(localStorage.getItem('access_token') === null){
        window.location.href = '/login'
      }
      else {
          refreshPlaidItemList(customer_id);
          refreshAccountList(customer_id);
          setLoading(false);
      }
    }, [customer_id]);

    useEffect(() => {
      if (location.state?.message) {
        setMessage(location.state.message);
        setTimeout(() => setMessage(""), 3000);
      }
    }, [location.state]);

    const refreshPlaidItemList = (id) => {
      if (id) {
        setLoading(true);
        axios
        .get(`/api/v2/customers/${id}/plaiditems`,
            {headers: {'Content-Type': 'application/json'}},
            {withCredentials: true})
        .then((res) => {
          setPlaidItemList(res.data);
          setLoading(false);
        })
        .catch((err) => console.log(err));
      }
    };

    const addPlaidItemToList = (plaid_item) => {
      setPlaidItemList((prevList) => [...prevList, plaid_item]);
      context.fireToast("Success", "Bank created.", "success")
    };

    const removePlaidItemFromList = (plaid_item_id) => {
      setPlaidItemList((prevList) =>
        prevList.filter((plaid_item) => plaid_item.id !== plaid_item_id)
      );
      context.fireToast("Success", "Bank deleted.", "success")
    };

    const refreshAccountList = (id) => {
      if (id) {
        axios
        .get(`/api/v2/customers/${id}/accounts/?type__name__in=Bank,Credit Card&is_main_account=false`,
            {headers: {'Content-Type': 'application/json'}},
            {withCredentials: true})
        .then((res) => {
          setAccountList(res.data.map((a) => ({"value": a.id, "label": a.number + " - " + a.name})));
        })
        .catch((err) => console.log(err));
      }
    }

    return (
      <Container maxW="100%" bg="brand.50" h='calc(91vh)'>
        <Stack direction='column' spacing={2} align='center'>
        {message && (
          <Alert color="success" className="text-center">
            {message}
          </Alert>
        )}
        <CustomTableContainer width="100%">
          <Flex pb="5">
            <Box>
              <Heading as='h3' size='lg'>Plaid Listing</Heading>
            </Box>
            <Spacer />
            <Box>
              <Stack direction='row' spacing={6} align='center'>
                <PlaidItemCreate
                  customer_id={customer_id}
                  addPlaidItemToList={addPlaidItemToList}
                />
                <LinkPlaid customer_id={customer_id} refreshList={refreshPlaidItemList} setLoading={setLoading}/>
                <div>{loading && <FontAwesomeIcon className="float-end fa-spin" icon={faSpinner} color="green" />}</div>
              </Stack>
            </Box>
          </Flex>
            <Table variant="unstyled" width="100%">
              <Thead>
                <Tr>
                  <Th>Action</Th>
                  <Th>Name</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {plaidItemList && plaidItemList.map((item) => (
                  <PlaidItem key={item.id} item={item} customer_id={customer_id} accountList={accountList} setLoading={setLoading} removePlaidItemFromList={removePlaidItemFromList} isAdmin={isAdmin} />
                ))}
              </Tbody>
            </Table>
        </CustomTableContainer>
        </Stack>
      </Container>
    );
}

const PlaidItem = ({item, customer_id, accountList, setLoading, removePlaidItemFromList, isAdmin}) => {
  return (
    <Tr key={item.id}>
      <Td>
          <HStack>
            <ClientPLaidItemDelete
              customer_id={customer_id}
              plaiditem_id={item.id}
              removePlaidItemFromList={removePlaidItemFromList}
            />
            {item.plaiditemerror_set.map((error) => (
              <Tooltip key={error.id} label={error.message} fontSize="md">
                <AlertCircle color="red" />
              </Tooltip>
            ))}
          </HStack>
      </Td>
      <Td>
        <b>{item.name}</b>
      </Td>
      <Td>
        <Table variant="unstyled" size='sm' width="100%">
          <Thead>
            <Tr>
              <Th></Th>
              <Th>Account Name</Th>
              <Th>QB Name</Th>
              {isAdmin && <Th>Account Link</Th>}
              <Th>Type</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {item.bankaccount_set.map((account) => (
              <BankAccount key={account.id} account={account} accountList={accountList} setLoading={setLoading} isAdmin={isAdmin}/>
            ))}
          </Tbody>
        </Table>
      </Td>
    </Tr>

  );
}

const BankAccount = ({account, accountList, setLoading, isAdmin}) => {
  const context = React.useContext(Context);
  const updateBankAccount = (selectedAccount) => {
    setLoading(true);

    const formattedAccountData = { accounting_account_id: selectedAccount };

    axios.patch(`/api/v2/bankaccounts/${account.id}/`, formattedAccountData)
    .then((res) => {
        setLoading(false);
        context.fireToast("Success", "Account updated", "success")
    })
    .catch((err) => console.log(err));
  };

  return (
    <Tr key={account.id}>
      <Td>
      {account.plaid_linked ?
        <FontAwesomeIcon className="float-end" icon={faLink} color="green" /> :
        <FontAwesomeIcon className="float-end" icon={faUnlink} color="red"/>
      }
      </Td>
      <Td>{account.name}</Td>
      <Td>
          <EditQuickbooksName
            bankAccountId={account.id}
            initialName={account.quickbooks_name ? account.quickbooks_name : "..."}
          />
      </Td>
      {isAdmin && (
        <DropDown name="account" options={accountList} selectedValue={account.accounting_account?.id} onChange={(account) => updateBankAccount(account.value)} w="350px"/>
      )}
      <Td>{account.subtype}</Td>
      <Td>
          <PlaidBankTransactionExport account_id={account.id} />
      </Td>
    </Tr>
  );
};
