import React, { useState } from "react"
import axios from "axios";
import { Button, Tr, Td, Spinner, HStack } from "@chakra-ui/react";
import { VendorSelect } from "../account/vendorDropDown"
import { oppositeType, renderAmount } from "../../utils";
import { DropDown } from "../generic/dropDown";

export const TransactionRow = ({ transaction, bankAccount, accountList, replaceTransaction, splitTransaction, linkTransaction, customer_id }) => {
    const [loading, setLoading] = useState(false);

    const isLinked = () => {
        if (transaction.journal_entries.length === 0) {
            return false;
        }
        let entriesLinked = transaction.journal_entries.every(e => e.account);
        return entriesLinked && transaction.vendor;
    }

    const getSelectedAccount = (transaction) => {
      for (let entry of transaction.journal_entries) {
        if (entry.account !== bankAccount.accounting_account.id) {
            return entry.account;
        }
      }
    };

    const updateJournalEntry = (account) => {
        var data = {journal_entries: []};
        if (transaction.journal_entries.length > 0) {
            var entry = transaction.journal_entries.find(e => {
                return e.account !== bankAccount.accounting_account.id
            })
            entry.account = account.value;
            data.journal_entries.push(entry);
        } else {
            const account_type = bankAccount.accounting_account.account_type;
            let bank_account_type = transaction.amount <= 0 ? account_type : oppositeType(account_type);
            if (account_type === "credit") {
                bank_account_type = transaction.amount <= 0 ? oppositeType(account_type) : account_type;
            }
            data.journal_entries.push({
                customer: customer_id,
                account: bankAccount.accounting_account.id,
                entry_type: bank_account_type,
                date: transaction.date,
                amount: Math.abs(transaction.amount),
                description: transaction.name,
                added: false
            })
            data.journal_entries.push({
                customer: customer_id,
                account: account.value,
                entry_type: oppositeType(bank_account_type),
                date: transaction.date,
                amount: Math.abs(transaction.amount),
                description: transaction.name,
                added: false
            })
        }

        updateTransaction(data);
    };

    const updateVendor = (vendor) => {
        var data = {vendor: vendor.value};
        if (transaction.journal_entries.length > 0) {
            data["id"] = transaction.journal_entries[0].id
        }
        updateTransaction(data);
    }

    const addJournalEntry = () => {
        var data = {journal_entries: []};
        for (let entry of transaction.journal_entries) {
            entry.added = true;
            data.journal_entries.push(entry);
        }
        updateTransaction(data, "remove");
    }

    const updateTransaction = (data, action="replace") => {
        setLoading(true);
        axios.patch(`/api/v2/bankaccounts/${transaction.bankaccount}/transactions/${transaction.id}/`, data)
        .then((res) => {
            replaceTransaction(res.data, action);
            setLoading(false);
        })
        .catch((err) => console.log(err));
    };

    return (
        <Tr key={transaction.id}>
            <Td>
                {transaction.date}
            </Td>
            <Td>
                {transaction.name}
            </Td>
            <Td w="350px">
                <DropDown name="account" options={accountList} selectedValue={getSelectedAccount(transaction)} onChange={updateJournalEntry} />
            </Td>
            <Td w="350px">
                <VendorSelect selectedVendorID={transaction.vendor} onChange={updateVendor} />
            </Td>
            <Td>
                {transaction.amount > 0 && renderAmount(Math.abs(transaction.amount))}
            </Td>
            <Td>
                {transaction.amount <= 0 && renderAmount(Math.abs(transaction.amount))}
            </Td>
            <Td>
                <HStack p="0" direction='row' spacing={2} align='center'>
                    <Button variant='outline' onClick={() => splitTransaction(transaction)}>Split</Button>
                    <Button variant='outline' onClick={() => linkTransaction(transaction)}>Link</Button>
                    {isLinked() && !loading && <Button colorScheme="brand" onClick={addJournalEntry}>Add</Button>}
                    {loading && <Spinner color="brand.600"/>}
                </HStack>
            </Td>
        </Tr>
    )
}
