
import React, { useEffect, useState } from "react"
import { Box, Button, Container, Flex, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, ModalFooter, Table, Tbody, Td, Th, Thead, Tr, useDisclosure, FormControl, FormLabel, Input, Text, Spacer, Stack } from "@chakra-ui/react"
import CustomTableContainer from "../../theme/components/tableContainer"
import axios from "axios";
import { Context } from "../../ContextWrapper";

export const ClientJournals = ({customer_id}) => {
    const [journalList, setJournalList] = useState([]);
    const [loading, setLoading] = useState(true);
    const { isOpen: isUploadOpen, onOpen: onUploadOpen, onClose: onUploadClose } = useDisclosure()
    const { isOpen: isErrorOpen, onOpen: onErrorOpen, onClose: onErrorClose } = useDisclosure()
    const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure()
    const context = React.useContext(Context);
    const [error, setError] = useState([]);

    const handleUpload = (file) => {
        const formData = new FormData();
        formData.append('file_uploaded', file);

        setLoading(true);
        axios.post(`/api/v2/customers/${customer_id}/glimport/`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
            withCredentials: true
        })
        .then((response) => {
            console.log(response.status);
            console.log(response.response);

            if (response.status === 200) {
                onUploadClose();
                refreshJournalList(customer_id);
                context.fireToast("Success", "Successfully imported the GL Report", "success");
            } else {
                onUploadClose();
                setLoading(false);
                setError(response.response.data.errors)
                onErrorOpen();
            }
        })
        .catch((error) => {
            context.fireToast("Error", "There was an error uploading the file!", "error");
            console.error("There was an error uploading the file!", error);
        })
    }

    const refreshJournalList = (customer_id) => {
        if (customer_id) {
            axios
            .get(`/api/v2/customers/${customer_id}/journals/`,
                {headers: {'Content-Type': 'application/json'}},
                {withCredentials: true})
            .then((res) => {
                setJournalList(res.data);
                setLoading(false);
            })
            .catch((err) => console.log(err));
        }
    };

    useEffect(() => {
        refreshJournalList(customer_id);
    }, [customer_id]);

    const downloadTemplate = () => {
        console.log("downloading templte");
        setLoading(true);
        axios
        .post(`/api/v2/customers/${customer_id}/glexport/`, {},
            {headers: {'Content-Type': 'application/json'}},
            {withCredentials: true})
        .then((response) => {
            const link = document.createElement('a');
            link.href = response.data.file;
            link.setAttribute('download', response.data.name);
            document.body.appendChild(link);
            link.click();
            link.remove();
            setLoading(false);
        })
        .catch((err) => console.log(err));
    }

    return (
        <Container maxW="100%" bg="brand.50" h='calc(91vh)' >
            <Stack direction='column' spacing={2} align='center'>
                <CustomTableContainer width="100%">
                    <UploadModal isOpen={isUploadOpen} onClose={onUploadClose} loading={loading} handleUpload={handleUpload} />
                    <ErrorModal isOpen={isErrorOpen} onClose={onErrorClose} errors={error} />
                    <DeleteAccountingDataModal isOpen={isDeleteOpen} onClose={onDeleteClose} customer_id={customer_id} refreshJournalList={refreshJournalList} />
                    <Flex pb="5">
                        <Box>
                            <Heading as='h3' size='lg'>Journals</Heading>
                        </Box>
                        <Spacer />
                        <Box>
                            <Stack direction='row' spacing={6} align='center'>
                                <Button colorScheme="red" onClick={onDeleteOpen}>Delete accounting data</Button>
                                <Button onClick={downloadTemplate}>GL Template</Button>
                                <Button onClick={onUploadOpen}>Import GL</Button>
                            </Stack>
                        </Box>
                    </Flex>
                    <Box maxHeight='calc(100vh - 350px)' bg="white" overflowX="auto">
                        <Table variant="unstyled">
                            <Thead position="sticky" top={-1} zIndex={1}>
                                <Tr>
                                    <Th>
                                        Date
                                    </Th>
                                    <Th>
                                        Number
                                    </Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {journalList.map((journal) => (
                                    <Tr key={journal.id}>
                                        <Td>{journal.date}</Td>
                                        <Td>{journal.number}</Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </Box>
                </CustomTableContainer>
            </Stack>
        </Container>
    )
}

const UploadModal = ({isOpen, onClose, loading, handleUpload}) => {
    const [file, setFile] = useState(null);

    const onUploadFile = (e) => {
        setFile(e.target.files[0])
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Upload GL</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl isRequired>
                        <FormLabel>Files</FormLabel>
                        <Input
                            type="file"
                            accept=".xlsx"
                            sx={{
                            "::file-selector-button": {
                                height: 10,
                                padding: 0,
                                mr: 4,
                                background: "none",
                                border: "none",
                                fontWeight: "bold",
                            },
                            }}
                            onChange={onUploadFile}
                        />
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <Button variant="outline" mr={3} onClick={onClose}>Cancel</Button>
                    <Button onClick={() => handleUpload(file)} isDisabled={loading} >
                        {loading ? "Processing..." : "Upload"}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

const ErrorModal = ({isOpen, onClose, errors}) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Upload Errors</ModalHeader>
                <ModalCloseButton />
                <ModalBody>

                    {errors && errors.map((e, i) => (
                        <>
                            <Text key={i} as="b">{e.error}</Text>
                            {e.detail.map((d, i2) => (
                                <Text key={i+i2} mb={0}>{d}</Text>
                            ))}
                        </>
                    ))}
                    {!errors && <Text>Server error, please check template format</Text>}
                </ModalBody>
                <ModalFooter>
                    <Button variant="outline" mr={3} onClick={onClose}>Close</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}


const DeleteAccountingDataModal = ({isOpen, onClose, customer_id, refreshJournalList}) => {
    const deleteAccountingData = () => {
        axios
        .post(`/api/v2/customers/${customer_id}/deleteaccounting/`, {},
            {headers: {'Content-Type': 'application/json'}},
            {withCredentials: true})
        .then((response) => {
            refreshJournalList(customer_id)
            onClose();
        })
        .catch((err) => console.log(err));
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Delete Accounting data.</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                You are about to PERMANENTLY delete: <br />
                Journal entries <br />
                Journals <br />
                Account balances <br />
                Transaction vendor links <br />
                Bank account review counts <br />
                <br />
                This can't be undone.
                </ModalBody>
                <ModalFooter>
                    <Button variant="outline" mr={3} onClick={onClose}>Cancel</Button>
                    <Button colorScheme="red" onClick={deleteAccountingData} >Delete</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
