import React, { useState, useEffect } from "react";
import { Select } from "chakra-react-select";
import { Box } from "@chakra-ui/react";

export const DropDown = ({
  name,
  options,
  selectedValue,
  onChange,
  metaData,
  w,
}) => {
  const [selected, setSelected] = useState();

  useEffect(() => {
    setSelected(options.find((a) => a.value === selectedValue));
  }, [options, selectedValue]);

  const onChangeLocal = (option) => {
    setSelected(option);
    onChange(option, metaData);
  };

  return (
    <Box w={w ? w : null} zIndex={999}>
      <Select
        name={name}
        options={options}
        value={selected}
        placeholder={`Select ${name}...`}
        onChange={onChangeLocal}
        useBasicStyles
      />
    </Box>
  );
};
