
import React, { useEffect, useState } from "react"
import { Box, Button, Container, Flex, Heading, Spacer, Stack, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import CustomTableContainer from "../../theme/components/tableContainer"
import axios from "axios";

export const ClientReconciliations = ({customer_id}) => {
    const [reconciliationList, setReconciliationList] = useState([]);
    const [loading, setLoading] = useState(true);


    const refreshReconciliationList = (customer_id) => {
        if (customer_id) {
            axios
            .get(`/api/v2/customers/${customer_id}/reconciliations/`,
                {headers: {'Content-Type': 'application/json'}},
                {withCredentials: true})
            .then((res) => {
                setReconciliationList(res.data);
                setLoading(false);
            })
            .catch((err) => console.log(err));
        }
    };

    useEffect(() => {
        refreshReconciliationList(customer_id);
    }, [customer_id]);


    return (
        <Container maxW="100%" bg="brand.50" h='calc(91vh)' >
            <Stack direction='column' spacing={2} align='center'>
                <CustomTableContainer width="100%">
                    <Flex pb="5">
                        <Box>
                            <Heading as='h3' size='lg'>Reconciliations</Heading>
                        </Box>
                        <Spacer />
                        <Box>
                            <Stack direction='row' spacing={6} align='center'>
                                <Button>Start</Button>
                            </Stack>
                        </Box>
                    </Flex>
                    <Box maxHeight='calc(100vh - 350px)' bg="white" overflowX="auto">
                        <Table variant="unstyled">
                            <Thead position="sticky" top={-1} zIndex={1}>
                                <Tr>
                                    <Th>
                                        Date
                                    </Th>
                                    <Th>
                                        Account
                                    </Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {reconciliationList.map((recon) => (
                                    <Tr key={recon.id}>
                                        <Td>{recon.created_on}</Td>
                                        <Td>{recon.account.id}</Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </Box>
                </CustomTableContainer>
            </Stack>
        </Container>
    )
}
