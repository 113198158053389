import { Skeleton, Stack } from "@chakra-ui/react";

export const renderAmount = (amount) => {
    if (!amount) {
      amount = 0;
    }
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      }).format(amount);
};

export const renderAmountWithType = (amount, type) => {
  if (!amount) {
    amount = 0;
  }

  amount = Math.abs(amount);
  if (type === "debit") {
    amount = amount * -1;
  }

  return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount);
};

export const oppositeType = (type) => {
  if (type === "debit") {
    return "credit";
  } else {
    return "debit";
  }
}

export const LoadingTable = () => {
  return (
    <Stack>
      <Skeleton height='20px' />
      <Skeleton height='20px' />
      <Skeleton height='20px' />
      <Skeleton height='20px' />
      <Skeleton height='20px' />
      <Skeleton height='20px' />
      <Skeleton height='20px' />
      <Skeleton height='20px' />
      <Skeleton height='20px' />
      <Skeleton height='20px' />
      <Skeleton height='20px' />
      <Skeleton height='20px' />
      <Skeleton height='20px' />
      <Skeleton height='20px' />
      <Skeleton height='20px' />
      <Skeleton height='20px' />
      <Skeleton height='20px' />
      <Skeleton height='20px' />
    </Stack>
  );
}
